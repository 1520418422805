import { createStore } from 'vuex'
import axios from '../src/axios';

// Create a new store instance.
const store = createStore({
    state: {
        user: null,
        posts: [],
        groups: [],
        notifications: [],
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            state.user.avatar = user.avatar;
        },
        setPosts(state, posts) {
            state.posts = posts;
        },
        addPost(state, post) {
            state.posts.unshift(post);
        },
        updatePost(state, updatedPost) {
            const index = state.posts.findIndex((post) => post.id === updatedPost.id);
            if (index !== -1) {
                state.posts.splice(index, 1, updatedPost);
            }
        },
        deletePost(state, postId) {
            state.posts = state.posts.filter((post) => post.id !== postId);
        },
        addGroup(state, group) {
            state.groups.push(group);
        },
        updateGroup(state, updatedGroup) {
            const index = state.groups.findIndex((g) => g.id === updatedGroup.id);
            if (index !== -1) {
                state.groups.splice(index, 1, updatedGroup);
            }
        },
        deleteGroup(state, groupId) {
            state.groups = state.groups.filter((g) => g.id !== groupId);
        },
        addUsersToGroup(state, { groupId, userIds }) {
            const group = state.groups.find((g) => g.id === groupId);
            if (group) {
                group.userIds = [...new Set([...group.userIds, ...userIds])];
            }
        },
        removeUserFromGroup(state, { groupId, userId }) {
            const group = state.groups.find((g) => g.id === groupId);
            if (group) {
                group.userIds = group.userIds.filter((id) => id !== userId);
            }
        },
        addImages(state, images) {
            state.images.push(...images);
        },
        addUser(state, user) {
            state.users.push(user);
        },
        updateUserStatus(state, { userId, status }) {
            const user = state.users.find(u => u.id === userId);
            if (user) {
                user.status = status;
            }
        },
        addNotification(state, notification) {
            state.notifications.unshift(notification);
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        removeNotification(state, notificationId) {
            state.notifications = state.notifications.filter(
                (n) => n.id !== notificationId
            );
        },
    },
    actions: {
        async fetchUser({ commit }) {
            try {
                const response = await axios.get('/get_user');
                commit('setUser', response.data);

            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        },
        async createPost({ commit }, postData) {
            try {
                await axios.post('/posts', postData);
            } catch (error) {
                console.error('Failed to create post:', error);
            }
        },
        async updatePost({ commit }, postData) {
        try {
            console.log(postData);
            const response = await axios.put(`/posts/${postData.id}`, postData);
            return response.data.post;
        } catch (error) {
            console.error('Failed to update post:', error.response?.data || error.message);
            throw error;
        }
},
        async deletePost({ commit }, postId) {
            try {
                await axios.delete(`/posts/${postId}`);
                commit('deletePost', postId);
            } catch (error) {
                console.error('Failed to delete post:', error);
            }
        },
        joinGroup({ commit }, { groupId, userId }) {
            commit('addUserToGroup', { groupId, userId });
        },
        leaveGroup({ commit }, { groupId, userId }) {
            commit('removeUserFromGroup', { groupId, userId });
        },
        addUsersToGroup({ commit }, { groupId, userIds }) {

            commit('addUsersToGroup', { groupId, userIds });
        },
        removeUserFromGroup({ commit }, { groupId, userId }) {

            commit('removeUserFromGroup', { groupId, userId });
        },
        deleteGroup({ commit }, groupId) {

            commit('deleteGroup', groupId);
        },
    },
    // modules: {
    //     user: userStore,
    // },
    getters: {
        signed_in(state) {
            return !!state.user;
        },
        isAdmin: (state, getters, rootState) => {
            return rootState.currentUser.role === 'admin';
        },
    },
});

export default store;
