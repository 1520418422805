const routes = [
    {
        path: '',
        component: () => import('@/pages/MainPage.vue'),
        name: 'main',
        meta: { title: 'Main' },
        children: [
            {
                path: '',
                component: () => import('@/pages/home/Home.vue'),
                name: 'home',
                meta: { title: 'Home' }
            },
            {
                path: 'explore',
                component: () => import('@/doesntuse/Explore.vue'),
                name: 'explore',
                meta: { title: 'Explore' }
            },
            {
                path: 'profile/:userId',
                component: () => import('@/pages/profile/Profile.vue'),
                name: 'profile',
                meta: { title: 'Profile' }
            },
            {
                path: 'groups',
                component: () => import('@/pages/groups/Groups.vue'),
                name: 'groups',
                meta: { title: 'Groups' }
            },
            {
                path: '/groups/:groupId',
                component: () => import('@/pages/groups/GroupDetailPage.vue'),
                name: 'GroupDetail',
                meta: { title: 'Group Detail' }
            },
            { path: '/users',
                component: () => import('@/doesntuse/UsersPage.vue'),
                name: 'users',
                meta: { title: 'Users' }
            },
            {
                path: 'notifications',
                component: () => import('@/pages/notifications/Notifications.vue'),
                name: 'notifications',
                meta: { title: 'Notifications' }
            },
            {
                path: 'admin',
                component: () => import('@/pages/admin/AdminPanel.vue'),
                name: 'admin',
                meta: { title: 'Admin Panel', requiresAdmin: true }
            },
            {
                path: '/user_not_found',
                component: () => import('@/pages/notfound/UserNotFound.vue'),
                name: 'user_not_found',
                meta: { title: 'User Not Found' }
            },
        ],
    },
    {
        path: '/signin',
        component: () => import('@/pages/credentials/SignIn.vue'),
        name: 'signin',
        meta: { title: 'Sign In' }
    },
    {
        path: '/signup',
        component: () => import('@/pages/credentials/SignUp.vue'),
        name: 'signup',
        meta: { title: 'Sign Up' }
    },

    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/notfound/PageNotFound.vue'),
        meta: { title: 'Page Not Found' }
    }

]

export default routes;
