import { createApp } from 'vue'
import App from './App.vue'
import store from '../stores/mainStore.js'
import { createWebHistory, createRouter } from 'vue-router'
import 'bootstrap'
import routes from '../routes.js'
import VueSweetalert2 from 'vue-sweetalert2';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura'

import 'sweetalert2/dist/sweetalert2.min.css';
import {definePreset} from "@primevue/themes";
import './styles/main.scss'


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach((to, from) => {

    document.title = to.meta.title || 'FITstagram';
});

const app = createApp(App)

const myPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{pink.50}',
            100: '{pink.100}',
            200: '{pink.200}',
            300: '{pink.300}',
            400: '{pink.400}',
            500: '{pink.500}',
            600: '{pink.600}',
            700: '{pink.700}',
            800: '{pink.800}',
            900: '{pink.900}',
            950: '{pink.950}'
        },
        surface: {
            base: '{white}',
            contrast: '{black}'
        }
    },
})

app.use(router)
app.use(store)
app.use(VueSweetalert2);


app.use(PrimeVue, {
    theme: {
        preset: myPreset,
        options: {
            darkModeSelector: '.my-app-dark',
        }
    }

});

app.mount('#app')

// Vue.use(require('vue-moment'));

export default router;
