<template>
    <router-view v-slot="{ Component }">
        <component :is="Component"/>
    </router-view>
    <Toast ref="toast"/>
</template>

<script setup>
    import {onMounted, onBeforeUnmount, ref} from 'vue';
    import axios from 'axios';
    import {useStore} from 'vuex';
    import {useRouter} from 'vue-router';
    import Toast from 'primevue/toast';

    const store = useStore();
    const router = useRouter();

    const toast = ref(null);

    const fetchUser = async () => {
        try {
            const response = await axios.get('/get_user');
            console.log('User is logged in:', response.data);
            store.commit('setUser', {
                id: response.data.id,
                username: response.data.username,
                email: response.data.email,
                avatar: response.data.avatar,
                status: response.data.status,
            });
        } catch (error) {
            console.error('Login failed:', error.response ? error.response.data : error.message);
        }
    };

    const fetchNotifications = async () => {
        try {
            if (store.state.user?.id) {
                const response = await axios.get('/notifications');
                store.commit('setNotifications', response.data);
            }
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        }
    };

    let socket;

    const handleMessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            const userId = store.state.user.id;
            console.log('WebSocket message:', data);
            if (data.data.receiver_user_id == userId) {
                const notification = {
                    id: data.data.notification_id || Date.now(),
                    user_id: userId,
                    created_at: new Date().toISOString(),
                    message: data.data.message,
                    is_read: false,
                };

                store.commit('addNotification', notification);

                if (data.msg_type == 'like') {
                    toast.value.add({
                        severity: 'success',
                        summary: 'Like received!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'comment') {
                    toast.value.add({
                        severity: 'success',
                        summary: 'New comment received!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'group_request') {
                    toast.value.add({
                        severity: 'info',
                        summary: 'New group request!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'group_request_approved') {
                    toast.value.add({
                        severity: 'success',
                        summary: 'Group request approved!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'group_left') {
                    toast.value.add({
                        severity: 'info',
                        summary: 'Group left!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'group_deleted') {
                    toast.value.add({
                        severity: 'info',
                        summary: 'Group deleted!',
                        detail: data.data.message,
                        life: 3000,
                    });
                } else if (data.msg_type == 'user_left_the_group') {
                    toast.value.add({
                        severity: 'success',
                        summary: 'Group left!',
                        detail: data.data.message,
                        life: 3000,
                    });
                }
            }
        } catch (err) {
            console.error('Error parsing WebSocket message:', err);
        }
    };

    const handleError = (error) => {
        console.error('WebSocket error:', error);
        toast.value.add({
            severity: 'error',
            summary: 'WebSocket Error',
            detail: 'An error occurred with the WebSocket connection.',
            life: 3000,
        });
    };

    const handleClose = (event) => {
        console.log('WebSocket closed:', event);
    };

    import config from '../config';

    onMounted(async () => {
        await fetchUser();
        if (store.state.user?.id) {
            axios.defaults.withCredentials = true;
            axios.defaults.baseURL = `${config.internet_protocol}://${config.baseURL}/api`;

            await fetchNotifications();

            socket = new WebSocket(`${config.websockets_protocol}://${config.baseURL}/ws/chat/`);

            socket.addEventListener('open', () => {
                console.log('WebSocket connected');
            });

            socket.addEventListener('message', handleMessage);
            socket.addEventListener('error', handleError);
            socket.addEventListener('close', handleClose);
        }
    });

    onBeforeUnmount(() => {
        if (socket && store.state.user?.id) {
            socket.removeEventListener('message', handleMessage);
            socket.removeEventListener('error', handleError);
            socket.removeEventListener('close', handleClose);
            socket.close();
        }
    });
</script>

<style>
html {
    overflow-y: scroll;
}

#app {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333333;
    background: #ffffff;
    height: 100vh;
}

a {
    text-decoration: none;
    color: inherit;
}

@import '~bootstrap/dist/css/bootstrap.css';
@import 'styles/animations.css';
</style>
